body {
  font-family: Arial, sans-serif;
  font-size: 20px;
  font-weight: 300;
}
.app {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.navbar--hidden {
  opacity: 0;
}
.nav-brand {
  margin-top: 30px;
  margin-bottom: 20px;
  font-size: 50px !important;
  color: white !important;
  font-weight: 300 !important;
  line-height:0.7 !important;
}

.font-300 {
  font-weight: 300 !important;
}

.app-background {
  background-color: #282c34;
  width: 100vw;
  min-height: 50vh;
  display: flex;
  flex-direction: column;
  /* align-items: center;
  justify-content: center; */
  color: white;
  background-image: url("backgroundimage3.jpg");
  height:700px; /* You must set a specified height */
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover the entire container */
  opacity: 0.92;
}

.App-link {
  color: #09d3ac;
}

li {
  color: rgb(42, 75, 42);
}

.footer {
  height: 150px;
}

.backgrounddark{
  background-color: #282c34;
}

.hoverable.a:hover {
  text-decoration: underline;
}

.ownimg {
   border-radius: 5%;
   box-shadow: 
   inset 0 -3em 3em rgba(0,0,0,0.1), 
         0 0 0 1px rgb(255,255,255),
         0em 0em 20px black;
}

.center-header {
  margin: auto;
  margin-top: 2em;
  font-weight: 300;
  font-size: 3em;
  white-space: no-wrap;
  animation-name: example;
  animation-duration: 3s;
}

@keyframes example {
  from { opacity: 0; font-size: 2em; }
  to { opacity: 1; font-size: 3em; }
}

.left {
  font-size: 20px;
  text-align: left;
  color: rgb(42, 75, 42);
}

.center {
  text-align: center;
}

.right {
  font-size: 20px;
  margin-left: 1em;
  text-align: center;
}

#services {
  margin-bottom: 5em;
}

#contact{
  width: 100vw;
  background-color: #282c34;
  color: white;
}

#company{
  width: 100vw;
  overflow: auto;
  background-color: #282c34;
  background-image: url("background_company.jpg");
  height:700px; /* You must set a specified height */
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover the entire container */
  opacity: 0.82;
}

.colorwhite {
  margin: auto 0;
  color: white;
}

.green {
  color: forestgreen;
}